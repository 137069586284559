import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CodeModel } from '../../../shared/models/code.model';
import { Observable } from 'rxjs';
import Endpoints from '../../../core/helpers/endpoints';

@Injectable({
  providedIn: 'root'
})
export class RefreshHttpService {

  BACKEND_URL = `${environment.APP_BACKEND_URL}`;

  constructor(private http: HttpClient) { }

  makeRequest(token: CodeModel): Observable<any> {
    const URL = `${this.BACKEND_URL}/${Endpoints.USER_AUTH_REFRESH_PATH}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response'
    };
    return this.http.post(URL, token, httpOptions);
  }
}
