import { Injectable } from '@angular/core';
import { StorageKind } from '../Enums/enums';
import { SearchCriteria } from '@simpl/element-ng';
import { ActivatedRoute } from '@angular/router';
import AppConstants from '../../core/helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AppUtilityService {

  /**
   * Gets the value of a specific cookie by its name.
   *
   * @param cookieName - The name of the cookie to retrieve.
   * @returns - The value of the cookie, or an empty string if the cookie is not found.
   */
  getCookieValue(cookieName: string): string {
    let cookie_value = '';
    document.cookie.split(';').forEach(coo => {
      if (coo.includes(cookieName)) {
        cookie_value = coo.split('=')[1];
      }
    });
    return cookie_value;
  }

  /**
   * Sets all properties of the given object to a specified value.
   *
   * @param obj - The object whose properties will be set to the specified value.
   * @param val - The value to which all properties of the object will be set.
   */
  setAllValueToNull(obj: any, val: any) {
    Object.keys(obj).forEach(k => obj[k] = val);
  }

  /**
   * Saves the filter criteria into the specified storage.
   *
   * @param storage - The type of storage to use (SESSION, LOCAL, or COOKIE).
   * @param activatedRoute - The activated route from Angular for the current component.
   * @param [searchCriteria] - Optional. The filter criteria to be saved.
   */
  saveFilterCriteria(storage: StorageKind, activatedRoute: ActivatedRoute, searchCriteria?: SearchCriteria) {

    if (searchCriteria) {
      let path = '';
      activatedRoute.url.subscribe(urlSegment => {
        path = urlSegment[0].path;
      });

      let sessionArray: { path: string; searchCriteria: SearchCriteria }[] = [];

      switch (storage) {
        case StorageKind.SESSION: {
          // Retrieve existing data from session storage
          const sessionData = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_KEY_SAVED_FILTER) || '[]');
          sessionArray = Array.isArray(sessionData) ? sessionData : [];
          const pathIndex = this.findObjectIndexByPath(sessionArray, path);

          // Update or add the filter criteria based on the path
          if (pathIndex !== -1) {
            sessionArray[pathIndex].searchCriteria = searchCriteria;
          } else {
            sessionArray.push({ path, searchCriteria });
          }

          // Save updated data back to session storage
          sessionStorage.setItem(AppConstants.SESSION_KEY_SAVED_FILTER, JSON.stringify(sessionArray));

          break;
        }
        case StorageKind.LOCAL: {
          // TODO: StorageKind.LOCAL implementation goes here if needed
          break;
        }
        case StorageKind.COOKIE: {
          // TODO: StorageKind.COOKIE implementation goes here if needed
          break;
        }
      }
    }

  }

  /**
   * Retrieves the filter criteria from the specified storage.
   *
   * @param storageKind - The type of storage from which to retrieve the filter criteria (SESSION, LOCAL, or COOKIE).
   * @param activatedRoute - The activated route from Angular for the current component.
   * @returns - The filter criteria retrieved from the storage, or an empty SearchCriteria object if not found.
   */
  getFilterCriteria(storageKind: StorageKind, activatedRoute: ActivatedRoute): SearchCriteria {

    let path = '';
    activatedRoute.url.subscribe(urlSegment => {
      path = urlSegment[0].path;
    });

    switch (storageKind) {
      case StorageKind.SESSION: {
        const sessionArray = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_KEY_SAVED_FILTER) || '[]');
        const pathIndex = this.findObjectIndexByPath(sessionArray, path);

        if (pathIndex !== -1) {
          return sessionArray[pathIndex].searchCriteria;
        } else {
          return { criteria: [], value: '' };
        }
      }
      case StorageKind.LOCAL:
        return { criteria: [], value: '' };

      case StorageKind.COOKIE:
        return { criteria: [], value: '' };

      default:
        return { criteria: [], value: '' };
    }

  }

  /**
   * Finds the index of an object within an array of objects based on a specified property value.
   *
   * @private
   * @param sessionArray - The array of objects to search through.
   * @param keyIndexToFind - The property value to search for.
   * @returns - The index of the found object, or -1 if not found.
   */
  private findObjectIndexByPath(sessionArray: {
    path: string;
    searchCriteria: SearchCriteria;
  }[], keyIndexToFind: string) {
    return sessionArray.findIndex(item => item.path === keyIndexToFind);
  };

}
