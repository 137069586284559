import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

/**
 * @author Hamayak Howsepjan (Z0049XYT)
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private userService: UserService,
    private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.redirectIfUnauthenticated(state);
  }

  /**
   * allow us to check if the user is not authenticated then redirect to the login page
   *
   * @param state
   * @private
   */
  private redirectIfUnauthenticated(state: RouterStateSnapshot): boolean {
    const user = this.userService.userValue;
    if (user) {
      // authorised so return true
      return true;
    }
    // no logged in so redirect to login page with the return url
    this.router.navigate(['/public/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
