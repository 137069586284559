import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { CodeModel } from '../../shared/models/code.model';
import { RefreshHttpService } from '../../private/http/refresh/refresh-http.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private refreshHttpService: RefreshHttpService,
    private tokenService: TokenService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      withCredentials: true
    });

    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 && this.tokenService.getFromCookieRefreshToken()) {
      const refreshToken: CodeModel = { code: this.tokenService.getFromCookieRefreshToken() };
      this.refreshHttpService.makeRequest(refreshToken).subscribe(() => {
        location.reload();
      });
    }
    return throwError(err);
  }
}
