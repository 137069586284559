import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CodeModel } from '../../shared/models/code.model';

/**
 * @author Hamayak Howsepjan (Z0049XYT)
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  BACKEND_URL = `${environment.APP_BACKEND_URL}`;

  constructor(private http: HttpClient) { }

  /**
   * with this function we make request to backend with parameter 'code' and expect JWT token from backend as response
   *
   * @param code
   */
  fetchJWT(code: CodeModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response'
    };
    const URL = `${this.BACKEND_URL}/v1/user/auth/code`;
    return this.http.post(URL, code, httpOptions);
  }

}
