import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../../shared/models/user.model';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import Endpoints from '../helpers/endpoints';
import { LoginService } from './login.service';
import { AES } from 'crypto-js';
import { enc } from 'crypto-js/core';
import AppConstants from '../helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private key = `${AppConstants.PRIVATE_KEY}`;
  private userSubject: BehaviorSubject<UserModel>;
  public user: Observable<UserModel>;

  BACKEND_URL = `${environment.APP_BACKEND_URL}`;

  constructor(
    private http: HttpClient,
    private loginService: LoginService) {
    // @ts-ignore
    this.userSubject = new BehaviorSubject<User>(this.getUser());
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): UserModel {
    return this.userSubject.value;
  }

  private setUser(user: UserModel) {
    const encryptedUser = AES.encrypt(JSON.stringify(user), this.key).toString();
    sessionStorage.setItem('user', encryptedUser);
  }

  private getUser(): any {
    const encryptedUser = sessionStorage.getItem('user');
    if (!encryptedUser) {
      return null;
    }
    const decryptedUser = AES.decrypt(encryptedUser, this.key).toString(enc.Utf8);
    return JSON.parse(decryptedUser);
  }

  getUserInfo(): Observable<UserModel> {
    const URL = `${this.BACKEND_URL}/${Endpoints.USER_INFO_PATH}`;
    return this.http.get<any>(URL, { withCredentials: true }).pipe(
      map((user: UserModel) => {
        // store user detaiils in session storage to keep user logged in between page refreshes
        this.setUser(user);
        this.userSubject.next(this.getUser());
        return user;
      })
    );
  }

  logout() {
    // remove user from session storage and set current user to null
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('returnUrl');
    sessionStorage.removeItem(AppConstants.SESSION_KEY_SAVED_FILTER);
    // @ts-ignore
    this.userSubject.next(null);
    this.loginService.redirectLoginURL();
  }


}
