export enum RoleEnum {
  ADMIN = 'Admin',
  CC_SUPERVISOR = 'CC-Supervisor',
  FAT_TESTER = 'FAT Tester',
  ORDER_MANAGER = 'Order Manager',
  PROJECT_MANAGER = 'Project Manager',
  WORKER = 'Worker',
  GUEST = 'Guest'
}

export enum StagesEnum {
  Not_STARTED = 'Not-Started',
  FAT_STARTED = 'FAT Started',
  FAT_STOPPED = 'FAT Stopped',
  PUNCHLIST_CREATED = 'PunchList Created',
  PUNCHLIST_CLOSED = 'PunchList Closed',
  PUNCHLIST_RE_OPENED = 'PunchList Re_Opened',
  PUNCHLIST_SIGNED_OM = 'PunchList Signed OM',
  PUNCHLIST_SIGNED_PM = 'PunchList Signed PM',
  REVISION_FINISHED = 'Revision Finished'
}

export enum StatusEnum {
  UNOPENED = 'Un-Opened',
  OPEN = 'Open',
  RELEASED = 'Released',
  RELEASED_WITH_RESTRICTIONS = 'Released-With-Restrictions'
}

export enum CauseEnum {
  FAULT_SWF = 'Fault SWF',
  INCORRECT_ORDER = 'Incorrect Order',
  CHANGE_REQUEST = 'Change Request',
  OTHERS = 'Others',
  MISSING_PART = 'Missing part'
}

export enum OfferRequiredEnum {
  YES = 'Yes',
  NO = 'No'
}

export enum OfferStatusEnum {
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  SELECT = 'Select'
}

export enum DoneByEnum {
  SWF = 'SWF',
  INFO = 'Info',
  ON_SITE = 'On site'
}

export enum ViewMode {
  View = 'View',
  Edit = 'Edit',
  Add = 'Add',
  Update = 'Update'
}

export enum PunchListScope {
  FAT = 'FAT',
  PUNCH = 'PUNCH'
}

export enum StorageKind {
  SESSION,
  LOCAL,
  COOKIE
}
