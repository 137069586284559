import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private router: Router) { }

  defaultRedirectURL() {
    const returnUrl = sessionStorage.getItem('returnUrl') || '/';
    this.router.navigate([returnUrl]);
  }

  redirectLoginURL() {
    this.router.navigate(['/public/login']);
  }

}
