import { Component, OnInit } from '@angular/core';
import { CodeModel } from '../../shared/models/code.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { SiToastNotificationService, ToastStateName } from '@simpl/element-ng';
import { concat } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { LoginService } from '../../core/services/login.service';

/**
 * @author Hamayak Howsepjan (Z0049XYT)
 */
@Component({
  templateUrl: './idpresponse.component.html'
})
export class IdpresponseComponent implements OnInit {

  loading = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private toastNotificationService: SiToastNotificationService
  ) {}

  ngOnInit(): void {
    this.checkQueryStringCode();
  }

  /**
   * Allow us to extract the code query parameter from the URL
   * and authenticate user with Authentication service
   *
   * @returns (void) we do not expect a return value
   */
  checkQueryStringCode(): void {
    const codeFromURL = this.route.snapshot.queryParamMap.get('code'); // get code query value from URL
    const code: CodeModel = { code: codeFromURL };
    if (code.code) {
      this.loading = true;
      concat(
        this.authService.fetchJWT(code),
        this.userService.getUserInfo()
      ).subscribe({
        next: () => {
          this.loading = false;
          this.loginService.defaultRedirectURL();
        },
        error: err => {
          this.loading = false;
          this.showToast('danger', 'Error', JSON.stringify(err.message));
          this.router.navigate(['/public/login']);
        },
        complete: () => {
          this.loading = false;
          this.showToast('success', 'Successful', 'Successfully logged in');
        }
      });
    }
  }

  showToast(state: ToastStateName, title: string, message: string, disableAutoClose?: boolean,
    disableManualClose?: boolean
  ) {
    this.toastNotificationService.queueToastNotification(state, title, message, disableAutoClose, disableManualClose);
  }

}
