import { Injectable } from '@angular/core';
import { AppUtilityService } from '../../shared/services/app-utility.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private appUtility: AppUtilityService
  ) { }

  getAuthToken() {
    return this.appUtility.getCookieValue('Authorization');
  }

  getFromCookieRefreshToken() {
    return this.appUtility.getCookieValue('refresh_token');
  }

}
