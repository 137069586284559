export default class Endpoints {

  private static API_VERSION = 'v1';

  // Users Path
  public static USER_PATH = `${Endpoints.API_VERSION}/user`;
  public static USER_PATH_COUNT = `${Endpoints.USER_PATH}/user_count`;
  public static USER_INFO_PATH = `${Endpoints.USER_PATH}/user_info`;
  public static USER_AUTH_PATH = `${Endpoints.USER_PATH}/auth`;
  public static USER_AUTH_CODE_PATH = `${Endpoints.USER_AUTH_PATH}/code`;
  public static USER_AUTH_REFRESH_PATH = `${Endpoints.USER_AUTH_PATH}/refresh`;
  public static USER_PM_PATH = `${Endpoints.USER_PATH}/pm_gid`;
  public static USER_OM_PATH = `${Endpoints.USER_PATH}/om_gid`;
  public static USER_TESTER_PATH = `${Endpoints.USER_PATH}/fat_testers`;

  // Punchlist Path
  public static PUNCHLIST_PATH = `${Endpoints.API_VERSION}/punchlist`;
  public static PUNCHLIST_TOTAL_COUNT = `${Endpoints.PUNCHLIST_PATH}/count`;
  public static PUNCHLIST_TOTAL_COUNT_Released = `${Endpoints.PUNCHLIST_PATH}/released_count`;
  public static FAT_OVERVIEW_PATH = `${Endpoints.PUNCHLIST_PATH}`;
  public static RELEASE_PUNCH_PATH = `${Endpoints.PUNCHLIST_PATH}/release`;
  public static UPDATE_PUNCH_STATUS = `${Endpoints.PUNCHLIST_PATH}/update_status`;
  public static CLOSE_PUNCHLIST = `${Endpoints.PUNCHLIST_PATH}/close_punch_list`;
  public static REOPEN_PUNCHLIST = `${Endpoints.PUNCHLIST_PATH}/re_open_punch_list`;
  public static PUNCHLIST_FINISH_REVISION = `${Endpoints.PUNCHLIST_PATH}/finish_revision`;
  public static PUNCHLIST_PM_SIGN = `${Endpoints.PUNCHLIST_PATH}/sign_punch_list_pm`;
  public static PUNCHLIST_OM_SIGN = `${Endpoints.PUNCHLIST_PATH}/sign_punch_list_om`;
  public static PUNCHLIST_SIGN_STATUS = `${Endpoints.PUNCHLIST_PATH}/get_signed_status`;
  public static PUNCHLIST_PANELS_STAGE = `${Endpoints.PUNCHLIST_PATH}/get_stage`;
  public static UPDATE_REQUEST_DELIVERY = `${Endpoints.PUNCHLIST_PATH}/update_requested_delivery_date`;

  // PUNCHLIST ASSISTANT PATH
  public static PUNCH_ASSIST_PATH = `${Endpoints.API_VERSION}/punchlist/cc`;
  public static PUNCH_ASSIST_UPDATE_PATH = `${Endpoints.PUNCHLIST_PATH}/update`;
  public static PUNCH_ASSIST_PATH_Count = `${Endpoints.API_VERSION}/punchlist/cc/count`;


  //CREATE PUNCHLIST
  public static CREATE_PUNCHLIST = `${Endpoints.PUNCHLIST_PATH}/create_punch_list`;

  // FAT Path
  public static FAT_PATH = `${Endpoints.API_VERSION}/fat`;
  public static FAT_TOTAL_COUNT = `${Endpoints.FAT_PATH}/fat_count`;
  public static FAT_PANELS = `${Endpoints.FAT_PATH}/panels`;
  public static FAT_GET_PANELS = `${Endpoints.FAT_PATH}/get_panels`;
  public static FAT_GET_PDF = `${Endpoints.FAT_PATH}/get_pdf`;
  public static FAT_GET_PDF_DATA = `${Endpoints.FAT_PATH}/get_fat_pdf_data`;
  public static FAT_OVERVIEW = `${Endpoints.FAT_PATH}/overview`;
  public static UPDATE_DONE_USER = `${Endpoints.FAT_PATH}/update_done_user`;
  public static UPDATE_CHECKED_USER = `${Endpoints.FAT_PATH}/update_checked_user`;
  public static UPDATE_IRRELEVANT = `${Endpoints.FAT_PATH}/update_irrelevant`;
  public static GET_PANELS_STATUS = `${Endpoints.FAT_PATH}/get_panels_status`;
  public static DELETE_PANEL_PATH = `${Endpoints.FAT_PATH}`;
  public static TYPICAL_PATH = `${Endpoints.FAT_PATH}/typical`;
  public static UPDATE_COMMENT = `${Endpoints.FAT_PATH}/update_comment`;

  // S3 Path
  public static S3_PATH = `${Endpoints.API_VERSION}/s3`;
  public static S3_UPLOAD_PATH = `${Endpoints.S3_PATH}/punchlist_attachments/upload_url`;
  public static S3_DOWNLOAD_PATH = `${Endpoints.S3_PATH}/punchlist_attachments/download_url`;
  public static S3_DELETE_PATH = `${Endpoints.S3_PATH}/punchlist_attachments/delete_url`;
  public static S3_DOWNLOAD_INFO_PATH = `${Endpoints.S3_PATH}/punchlist_attachments/download_info`;

  // START FAT
  public static START_FAT = `${Endpoints.PUNCHLIST_PATH}/start_fat`;

  // STOP FAT
  public static STOP_FAT = `${Endpoints.PUNCHLIST_PATH}/stop_fat`;

  //Update Panels
  public static UPDATE_PANELS = `${Endpoints.FAT_PATH}/update_panels`;

  //Open Falty Panels
  public static OPEN_FAULTY_PANELS = `${Endpoints.PUNCHLIST_PATH}/panels`;

  // Role Path
  public static ROLE_PATH = `${Endpoints.API_VERSION}/roles`;
}
