import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SiLoadingSpinnerModule, SiSearchBarModule, SiToastNotificationModule } from '@simpl/element-ng';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoResizeDirective } from './directives/auto-resize.directive';

@NgModule({
  imports: [
    CommonModule,
    SiToastNotificationModule
  ],
  declarations: [
    AutoResizeDirective
  ],
  exports: [
    CommonModule,
    TranslateModule,
    AutoResizeDirective,
    SiToastNotificationModule,
    SiLoadingSpinnerModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    SiSearchBarModule
  ]
})
export class SharedModule {}
