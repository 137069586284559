import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

/**
 * Store all application constants in one place,
 * so that exists consistently over all
 *
 * @author Hamayak Howsepjan (Z0049XYT)
 */
export default class AppConstants {

  // Private
  private static IDENTITY_PROVIDER = environment.IDENTITY_PROVIDER;
  private static AUTHORIZE_RESPONSE_TYPE = environment.AUTHORIZE_RESPONSE_TYPE;
  private static COGNITO_CLIENT_ID = environment.COGNITO_CLIENT_ID;
  private static AUTHORIZE_SCOPE = environment.AUTHORIZE_SCOPE;
  private static COGNITO_REDIRECT_URI = environment.COGNITO_REDIRECT_URI;
  private static COGNITO_DOMAIN_URL = environment.COGNITO_DOMAIN_URL;
  private static AUTHORIZE_URL = environment.AUTHORIZE_URL;

  // Public
  public static LOGIN_AUTHORIZE_URI = AppConstants.generateLoginURL();
  public static PRIVATE_KEY = 'y$B&E)H@McQfTjWnZq4t7w!z%C*F-JaN';

  /**
   * This function generates a URL for login button so that
   * after user's successful authorization cognito sends a code to client
   *
   * @returns string as Login URL with additional query parameters for User Authorization
   */
  private static generateLoginURL(): string {
    const params = new HttpParams()
      .set('identity_provider', this.IDENTITY_PROVIDER)
      .set('response_type', this.AUTHORIZE_RESPONSE_TYPE)
      .set('client_id', this.COGNITO_CLIENT_ID)
      .set('scope', this.AUTHORIZE_SCOPE)
      .set('redirect_uri', this.COGNITO_REDIRECT_URI);
    return this.COGNITO_DOMAIN_URL + this.AUTHORIZE_URL + '?' + params;
  }

  // Session storage keys
  public static SESSION_KEY_SAVED_FILTER = 'savedFilter';

}
