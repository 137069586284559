import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
  { path: 'main', loadChildren: () => import('./private/private.module').then(m => m.PrivateModule), canActivate: [AuthGuard] },
  { path: 'oauth2', loadChildren: () => import('./oauth2/oauth2.module').then(m => m.Oauth2Module), canActivate: [LoginGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouttingModule { }
