// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    APP_BACKEND_URL: 'https://api.digital-punchlist.swf-dev.si.siemens.cloud',
    COGNITO_DOMAIN_URL: 'https://d2go-mdp-si-swf-dev-digital-punchlist.auth.eu-west-1.amazoncognito.com',
    AUTHORIZE_URL: '/oauth2/authorize',
    AUTHORIZE_RESPONSE_TYPE: 'code',
    AUTHORIZE_SCOPE: 'email openid phone profile',
    IDENTITY_PROVIDER: 'AzureAD',
    COGNITO_CLIENT_ID: '6n4bihhnau3krn2lg8o1b2fsus',
    COGNITO_REDIRECT_URI: 'https://digital-punchlist.swf-dev.si.siemens.cloud/oauth2/idpresponse'
  };